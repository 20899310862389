import React from "react";

const Overlay = ({ open, close }) => {
    return(
        <div style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            display: (open ? "block" : "none")
        }} 
        onClick={close}/>
    )
}

export default Overlay