import React, { useState, useEffect } from "react";
import { DateRangePicker } from 'react-date-range';
import { addDays } from 'date-fns';
import { RangePickerModal, DoneButton } from "../style/dashboard";
import moment from "moment";

const DateRangePickerComp = ({ startDate, endDate, rangeSelected, onDonePressed }) => {
    const [range, setRange] = useState([
        {
            startDate: startDate,
            endDate: endDate,
            key: 'selection'
        }
    ]);

    useEffect(() => {
        console.log(startDate, endDate);
    }, [])

    const clearDate = () => {
        setRange([
            {
                startDate: startDate,
                endDate: endDate,
                key: 'selection'
            }
        ])
    }

    const dateChange = (selection) => {
        let newSelection = selection;
        if (moment(selection.endDate).diff(moment(selection.startDate), 'days') > 7) {
            newSelection = {
                startDate: selection.startDate,
                endDate: addDays(selection.startDate, 7),
                key: 'selection'
            }
        }
        setRange([newSelection]);
        rangeSelected([newSelection])
    }

    return (
        <RangePickerModal>
            <DateRangePicker
                onChange={item => {
                    dateChange(item.selection)
                }}
                // minDate={range[0].startDate.getTime() === range[0].endDate.getTime() ? null : addDays(range[0].startDate, -7)}
                // maxDate={range[0].startDate.getTime() === range[0].endDate.getTime() ? null : addDays(range[0].startDate, 7)}
                moveRangeOnFirstSelection={false}
                // minDate={addDays(range[0].startDate, -7)}
                // maxDate={addDays(range[0].startDate, 7)}
                ranges={range}
                rangeColors={["#9564ff"]}
                showDateDisplay={false}
                staticRanges={[]}
                inputRanges={[]}
            />
            <DoneButton onClick={onDonePressed}>
                Done
            </DoneButton>
        </RangePickerModal>
    )
}

export default DateRangePickerComp;