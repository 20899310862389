import React from "react";
import { TabsSection, Tab, SelectedTab } from "../style/dashboard";

const Tabs = ({ handleTab, selected = "all" }) => {

    return (
        <TabsSection>
            <Tab style={selected === "all" ? SelectedTab : {}} onClick={() => handleTab("all")}>
                All
            </Tab>
            <Tab style={selected === "daily" ? SelectedTab : {}} onClick={() => handleTab("daily")}>
                Daily
            </Tab>
            <Tab style={selected === "weekly" ? SelectedTab : {}} onClick={() => handleTab("weekly")}>
                Weekly
            </Tab>
            <Tab style={selected === "monthly" ? SelectedTab : {}} onClick={() => handleTab("monthly")}>
                Monthly
            </Tab>
            <Tab style={selected === "yearly" ? SelectedTab : {}} onClick={() => handleTab("yearly")}>
                Yearly
            </Tab>
            {/* <Tab style={selected === "custom" ? SelectedTab : {}} onClick={() => handleTab("custom")}>
                Custom
            </Tab> */}
        </TabsSection>
    )
}

export default Tabs;