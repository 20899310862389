import React, { useEffect, useState } from "react";
import { ChartSection } from "../style/dashboard"
import dynamic from 'next/dynamic';
const Chart = dynamic(() => import('react-apexcharts'), { ssr: false });

const LineGraph = ({
    title = "Grapgh",
    seriesName = "Sales",
    data = [30, 40, 35, 50, 49, 60, 70, 91, 125],
    categories = [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999],
    width = 0,
    height = 0,
    xaxisTitle = "Period",
    yaxisTitle = "Count"
}) => {

    const [cardWidth, setCardWidth] = useState(width);
    const [cardheight, setCardHeight] = useState(height);

    if(typeof window != "undefined"){
        useEffect(() => {
            if (window.document.getElementById("card") != null) {
                setCardWidth(window.document.getElementById("card").clientWidth - 60);
                setCardHeight(window.document.getElementById("card").clientHeight - 80);
            }
    
        }, [window.document.getElementById("card")])
    }

    let options = {
        title: {
            text: title,
            align: 'left',
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
                fontSize: '22px',
                fontWeight: 'bold',
                color: '#263238'
            },
        },
        stroke: {
            curve: 'smooth',
            lineCap: 'round',
        },
        tooltip: {
            enabled: true,
        },
        chart: {
            toolbar: {
                show: true,
                tools: {
                    download: true,
                    selection: true,
                    zoom: false,
                    zoomin: true,
                    zoomout: true,
                    pan: false,
                    reset: false,
                },
                export: {
                    csv: {
                        filename: title,
                        columnDelimiter: ',',
                        headerCategory: 'Period',
                    },
                    svg: {
                        filename: title,
                    },
                    png: {
                        filename: title,
                    }
                },
            },
        },
        colors: ['#9564ff'],
        yaxis: {
            title: {
                text: yaxisTitle,
                style: {
                    fontSize: '12px',
                    fontWeight: 400,
                },
            }
        },
        xaxis: {
            type: 'period',
            categories: categories,
            title: {
                text: xaxisTitle,
                style: {
                    fontSize: '12px',
                    fontWeight: 400,
                },
            }
        }
    }

    return (
        <ChartSection>
            <Chart
                type="line"
                series={[{
                    name: seriesName,
                    data: data
                }]}
                options={options}
                width={width === 0 ? cardWidth : width}
                height={height === 0 ?cardheight : height}
            />
        </ChartSection>
    )
}

export default LineGraph;