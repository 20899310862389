import React from "react";
import { DropDownWrapper, DropDownSelect, DropDownOption } from "../style/dashboard";

const DropDown = ({ data, onChange, defaultValue = 0, name, id, defaultOption = "Select", width = 150, height = 50 }) => {


    const createOptions = (inputArray) => {
        return inputArray.map((type) => (
            <DropDownOption value={Number(type.lookupId)} key={type.lookupId} name={name}>
                {type.lookupDescription}
            </DropDownOption>
        ))
    }

    return (
        <DropDownWrapper style={{ width: width }}>
            <DropDownSelect
                style={{
                    width: "98%",

                }}
                name={name}
                id={id}
                defaultValue={defaultValue}
                onChange={onChange}
            >
                <DropDownOption>{defaultOption}</DropDownOption>
                {createOptions(data)}
            </DropDownSelect>
        </DropDownWrapper>
    )
}

export default DropDown;