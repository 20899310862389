import React from "react";
import { TabsSectionCard, Tab, SelectedTab } from "../style/dashboard";

const VideoCategoryTabs = ({ selectedCategoryId = 0, data, onChange }) => {

    return (
        <TabsSectionCard>
            {data.map((item, i) => {
                return (<Tab style={selectedCategoryId === item.lookupId ? SelectedTab : {}} onClick={() => onChange(item)}>
                    {item.lookupDescription}
                </Tab>)
            })}
        </TabsSectionCard>
    )
}

export default VideoCategoryTabs;