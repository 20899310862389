import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RootSection, CardSection } from "../style/dashboard";
import CardContainer from "./Card";
import LineGraph from "./LineGraph";
import Tabs from "./Tabs";
import DropDown from "./DropDown";
import { fetchAptStat, fetchReqStat, fetchVidViewStat, fetchVideoCategories, fetchVidViewCategoryStat, fetchListenerHistoryStat, fetchMoodHistoryStat } from "../state";
import moment from "moment";
import VideoCategoryTabs from "./VideoCategoryTabs";

const DashboardData = ({
    fetchAptStat,
    fetchReqStat,
    fetchVidViewStat,
    fetchVideoCategories,
    fetchVidViewCategoryStat,
    fetchListenerHistoryStat,
    fetchMoodHistoryStat,
    aptCountData = {},
    reqCountData = {},
    vidViewCountData = {},
    listenerHistory = {},
    moodHistory = {},
    videocategories = [],
    user = {},
    onCustomPress,
    range,
    isRangeSelected,
    patientId,
    vidCategoryCountData,
}) => {
    const [selectedTab, setSelectedTab] = useState("all");
    const [aptStats, setAptStats] = useState({ data: [], categories: [] });
    const [reqStats, setReqStats] = useState({ data: [], categories: [] });
    const [vidViewStats, setVidViewStats] = useState({ data: [], categories: [] });
    const [listenerHistoryStats, setListenerHistoryStats] = useState({ data: [], categories: [] });
    const [moodHistoryStats, setMoodHistoryStats] = useState({ data: [], categories: [] });
    const [cardWidth, setCardWidth] = useState(0);
    const [cardHeight, setCardHeight] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState({ lookupId: 0, lookupDescription: "" });
    const [vidViewCategoryStats, setVidViewCategoryStats] = useState({ data: [], categories: [] });

    if(typeof window != "undefined"){
        useEffect(() => {
            if (window.document.getElementById("card") != null) {
                setCardWidth(window.document.getElementById("card").clientWidth - 80);
                setCardHeight(window.document.getElementById("card").clientHeight - 80);
            }
    
        }, [window.document.getElementById("card")])
    }

    useEffect(() => {
        fetchAptStat(user, patientId);
        fetchReqStat(user, patientId);
        fetchVidViewStat(user, patientId);
        fetchVideoCategories();
        fetchListenerHistoryStat(user, patientId);
        fetchMoodHistoryStat(user, patientId);
    }, [fetchAptStat, fetchReqStat, fetchVidViewStat, fetchVideoCategories, patientId]);

    useEffect(() => {
        if (videocategories.length > 0) {
            setSelectedCategory(videocategories[0]);
            fetchVidViewCategoryStat(user, videocategories[0], patientId);
        }
    }, [videocategories, patientId]);

    useEffect(() => {
        if (isRangeSelected) {
            console.log("range", range);
            let startDate = range.startDate;
            let endDate = range.endDate;
        }
    }, [range, isRangeSelected]);

    useEffect(() => {
        if (aptCountData.hasOwnProperty("all")) {
            let data = aptCountData[selectedTab].count;
            let categories = aptCountData[selectedTab].period;
            setAptStats({ data, categories });
        }
        if (reqCountData.hasOwnProperty("all")) {
            let data = reqCountData[selectedTab].count;
            let categories = reqCountData[selectedTab].period;
            setReqStats({ data, categories });
        }
        if (vidViewCountData.hasOwnProperty("all")) {
            let data = vidViewCountData[selectedTab].count;
            let categories = vidViewCountData[selectedTab].period;
            setVidViewStats({ data, categories });
        }
        if (listenerHistory.hasOwnProperty("all")) {
            let data = listenerHistory[selectedTab].count;
            let categories = listenerHistory[selectedTab].period;
            setListenerHistoryStats({ data, categories });
        }
        if (moodHistory.hasOwnProperty("all")) {
            let data = moodHistory[selectedTab].count;
            let categories = moodHistory[selectedTab].period;
            setMoodHistoryStats({ data, categories });
        }
        if (vidCategoryCountData.hasOwnProperty("all")) {
            let data = vidCategoryCountData[selectedTab].count;
            let categories = vidCategoryCountData[selectedTab].period;
            setVidViewCategoryStats({ data, categories });
        }
    }, [aptCountData, reqCountData, vidViewCountData, listenerHistory, moodHistory, vidCategoryCountData]);

    const handleTabChanged = (period) => {
        setSelectedTab(period);
        if (period !== "custom") {
            if (aptCountData.hasOwnProperty("all")) {
                let data = aptCountData[period].count;
                let categories = aptCountData[period].period;
                setAptStats({ data, categories });
            }
            if (reqCountData.hasOwnProperty("all")) {
                let data = reqCountData[period].count;
                let categories = reqCountData[period].period;
                setReqStats({ data, categories });
            }
            if (vidViewCountData.hasOwnProperty("all")) {
                let data = vidViewCountData[period].count;
                let categories = vidViewCountData[period].period;
                setVidViewStats({ data, categories });
            }
            if (listenerHistory.hasOwnProperty("all")) {
                let data = listenerHistory[period].count;
                let categories = listenerHistory[period].period;
                setListenerHistoryStats({ data, categories });
            }
            if (moodHistory.hasOwnProperty("all")) {
                let data = moodHistory[period].count;
                let categories = moodHistory[period].period;
                setMoodHistoryStats({ data, categories });
            }
            if (vidCategoryCountData.hasOwnProperty("all")) {
                let data = vidCategoryCountData[period].count;
                let categories = vidCategoryCountData[period].period;
                setVidViewCategoryStats({ data, categories });
            }
            onCustomPress(false);
        }
        else {
            onCustomPress(true);
        }
    }

    const handleCategoryChange = (e) => {
        let selectedCategory = videocategories.filter(c => c.lookupId === Number(e.target.value));
        if (selectedCategory.length > 0) {
            setSelectedCategory(selectedCategory[0]);
            fetchVidViewCategoryStat(user, selectedCategory[0]);
        }
    }

    const handleCategoryTabChange = (data) => {
        setSelectedCategory(data);
        fetchVidViewCategoryStat(user, data);
    }

    return (
        <RootSection>
            <Tabs
                selected={selectedTab}
                handleTab={(period) => { handleTabChanged(period) }}
            />
            <CardSection>
                <CardContainer>
                    <LineGraph
                        title={"Appointments"}
                        seriesName={"Appointments"}
                        data={aptStats.data}
                        categories={aptStats.categories}
                        xaxisTitle={"Period"}
                        yaxisTitle={"No. of appointments"}
                    />
                </CardContainer>
                <CardContainer>
                    <LineGraph
                        title={"Listener Requests"}
                        seriesName={"Requests"}
                        data={reqStats.data}
                        categories={reqStats.categories}
                        xaxisTitle={"Period"}
                        yaxisTitle={"No. of requests"}
                    />
                </CardContainer>
                <CardContainer>
                    <LineGraph
                        title={"Self Help"}
                        seriesName={"Self Help"}
                        data={vidViewStats.data}
                        categories={vidViewStats.categories}
                        xaxisTitle={"Period"}
                        yaxisTitle={"Duration (minutes)"}
                    />
                </CardContainer>
                {videocategories.length > 0 && <CardContainer>
                    {/* <DropDown
                        data={videocategories}
                        onChange={(e) => { handleCategoryChange(e) }}
                        defaultValue={videocategories[0].lookupId}
                        name={"category"}
                        id={"category"}
                        defaultOption={"Select Category"}
                        width={cardWidth}
                    /> */}
                    <VideoCategoryTabs
                        data={videocategories}
                        onChange={(categoryData) => { handleCategoryTabChange(categoryData) }}
                        selectedCategoryId={selectedCategory.lookupId}
                    />
                    <LineGraph
                        title={selectedCategory.lookupDescription}
                        seriesName={selectedCategory.lookupDescription}
                        data={vidViewCategoryStats.data}
                        categories={vidViewCategoryStats.categories}
                        xaxisTitle={"Period"}
                        yaxisTitle={"Duration (minutes)"}
                        width={cardWidth}
                        height={cardHeight - 30}
                    />
                </CardContainer>}
                <CardContainer>
                    <LineGraph
                        title={"Listener Conversation"}
                        seriesName={"Listener Conversation"}
                        data={listenerHistoryStats.data}
                        categories={listenerHistoryStats.categories}
                        xaxisTitle={"Period"}
                        yaxisTitle={"Duration (minutes)"}
                    />
                </CardContainer>
                <CardContainer>
                    <LineGraph
                        title={"Mood History"}
                        seriesName={"Mood History"}
                        data={moodHistoryStats.data}
                        categories={moodHistoryStats.categories}
                        xaxisTitle={"Period"}
                        yaxisTitle={"Score"}
                    />
                </CardContainer>
            </CardSection>
        </RootSection>
    )
}

const mapStateToProps = state => ({
    aptCountData: state.stats.aptCountData,
    reqCountData: state.stats.reqCountData,
    vidViewCountData: state.stats.vidViewCountData,
    user: state.login.user,
    videocategories: state.wellnessvideos.videocategories,
    listenerHistory: state.stats.listenerHistory,
    moodHistory: state.stats.moodHistory,
    vidCategoryCountData: state.stats.vidCategoryCountData,
})

const mapDispatchToProps = (dispatch) => {
    return {
        fetchAptStat: (user, patientId) => dispatch(fetchAptStat(user, patientId)),
        fetchReqStat: (user, patientId) => dispatch(fetchReqStat(user, patientId)),
        fetchVidViewStat: (user, patientId) => dispatch(fetchVidViewStat(user, patientId)),
        fetchVideoCategories: () => dispatch(fetchVideoCategories()),
        fetchVidViewCategoryStat: (user, category, patientId) => dispatch(fetchVidViewCategoryStat(user, category, patientId)),
        fetchListenerHistoryStat: (user, patientId) => dispatch(fetchListenerHistoryStat(user, patientId)),
        fetchMoodHistoryStat: (user, patientId) => dispatch(fetchMoodHistoryStat(user, patientId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardData);